import {
  ConfigureAuthFlowProps,
  useConfigureAuthFlow,
} from "@kartverket/frontend-aut-lib";
import { useSWRFetchers } from "api/useSWRFetchers";
import { EiendomInfo } from "features/EiendomInfo/EiendomInfo";
import { Faq } from "features/Faq/Faq";
import NotFound from "features/NotFound/NotFound";
import { Search } from "features/Search/Search";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppLayout } from "shared/components/AppLayout";
import { SWRConfig } from "swr";

const authFlowProps: ConfigureAuthFlowProps = {
  systemId: "se-eiendom",
  fallbackUrl: "/",
  afterUserLogoutRedirect: "/",
};

export const App = () => {
  document.documentElement.lang = "nb-NO";
  const { fetcher } = useSWRFetchers();
  const [redirectAfterLogon, redirectAfterLogout] =
    useConfigureAuthFlow(authFlowProps);
  return (
    <SWRConfig value={{ fetcher }}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <AppLayout>
                <Search />
              </AppLayout>
            }
          />
          <Route
            path="/eiendom/:kommunenr/:gaardsnr/:bruksnr/:festenr/:seksjonsnr"
            element={
              <AppLayout>
                <EiendomInfo />
              </AppLayout>
            }
          />
          <Route
            path="/eiendom/:kommunenr/:gaardsnr/:bruksnr/:festenr"
            element={
              <AppLayout>
                <EiendomInfo />
              </AppLayout>
            }
          />
          <Route
            path="/eiendom/:kommunenr/:gaardsnr/:bruksnr"
            element={
              <AppLayout>
                <EiendomInfo />
              </AppLayout>
            }
          />
          <Route
            path="/faq"
            element={
              <AppLayout>
                <Faq />
              </AppLayout>
            }
          />
          {redirectAfterLogout}
          {redirectAfterLogon}
          <Route
            path="/*"
            element={
              <AppLayout>
                <NotFound />
              </AppLayout>
            }
          />
        </Routes>
      </Router>
    </SWRConfig>
  );
};
