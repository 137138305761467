import { Box, Button, Link } from "@kvib/react";
import React from "react";

interface Props {
  asLink?: boolean;
  size?: "sm" | "md" | "lg" | undefined;
}

export const BestillUtskriftButton: React.FC<Props> = ({
  asLink,
  size = "lg",
}) => {
  return asLink ? (
    <Box>
      <Link
        isExternal
        href="https://www.kartverket.no/eiendom/bestille-fra-grunnboken/"
      >
        Bestill utskrift
      </Link>
    </Box>
  ) : (
    <Button
      variant="secondary"
      colorScheme="green"
      size={size}
      rightIcon="open_in_new"
      onClick={() =>
        window.open(
          "https://www.kartverket.no/eiendom/bestille-fra-grunnboken/",
          "_blank",
        )
      }
    >
      Bestill utskrift
    </Button>
  );
};
