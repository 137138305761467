import { Box, Flex, Link, Text } from "@kvib/react";
import { useAdresser } from "api/useAdresser";
import { useBorettslag } from "api/useBorettslag";
import { useEiendomInfo } from "api/useEiendomInfo";
import { isAxiosError } from "axios";
import NotFound from "features/NotFound/NotFound";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GreenContainer } from "shared/components/GreenContainer";

import { Feilmelding } from "../../shared/components/Feilmelding";
import { EiendomInfoHeader } from "./EiendomInfoHeader/EiendomInfoHeader";
import { GrunnboksInfo } from "./GrunnbokOgKart/GrunnboksInfo";
import { Kartutsnitt } from "./GrunnbokOgKart/Kartutsnitt";
import { MatrikkelInfo } from "./MatrikkelInfo/MatrikkelInfo";

export const EiendomInfoContent = () => {
  const { bruksnr, festenr, gaardsnr, kommunenr, seksjonsnr } = useParams();
  const ident = { bruksnr, festenr, gaardsnr, kommunenr, seksjonsnr };

  const { data: eiendomInfo, error, isLoading } = useEiendomInfo(ident);
  const { data: adresser } = useAdresser(ident);

  const { data: borettslag, isLoading: isLoadingBorettslag } =
    useBorettslag(ident);
  eiendomInfo?.matrikkelenhetident?.bruksnr;

  useEffect(() => {
    if (adresser?.length) {
      document.title = `Se eiendom - ${adresser[0].adresse}`;
    }
  }, [adresser]);

  if (error != undefined) {
    if (isAxiosError(error)) {
      switch (error.response?.status) {
        case 429:
          return (
            <Feilmelding title="For mange oppslag av eiendommer">
              <Text>
                Du eller noen på ditt nettverk har gjort for mange opplag av
                eiendommer. Vennligst vent litt før du prøver igjen. Ta kontakt
                med kundesenteret dersom dette er et vedvarende problem.
              </Text>
            </Feilmelding>
          );
        case 404:
          return (
            <Feilmelding title="Fant ikke eiendom">
              <Text>
                Vi kunne ikke finne denne eiendommen. Prøv å søk opp eiendommen
                på{" "}
                <Link href="http://norgeskart.no" isExternal>
                  norgeskart.no
                </Link>{" "}
                eller gjør ett <Link href="/">nytt søk</Link>.
              </Text>
            </Feilmelding>
          );
        case 400:
          return (
            <Feilmelding title="Ugyldig oppslag">
              <Text>Lenken du har oppgitt er ugyldig</Text>
            </Feilmelding>
          );
        default:
          return (
            <Feilmelding title="En uventet feil oppsto">
              <Text>
                Se eiendom har problemer med å hente eiendommen, prøv igjen
                senere.
              </Text>
            </Feilmelding>
          );
      }
    }

    return (
      <Feilmelding title="En uventet feil oppsto">
        <Text>
          Se eiendom har problemer med å hente eiendommen, prøv igjen senere.
        </Text>
      </Feilmelding>
    );
  }

  if (kommunenr == undefined || bruksnr == undefined || gaardsnr == undefined) {
    return <NotFound />;
  }

  return (
    <>
      <Box minHeight="44">
        <GreenContainer>
          <EiendomInfoHeader />
        </GreenContainer>
      </Box>
      <Flex
        as="section"
        minHeight={{ base: "3xs", md: "xs" }}
        paddingTop="10"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <GrunnboksInfo
          isLoading={isLoading || isLoadingBorettslag}
          borettslag={borettslag?.borettslag ?? false}
          ident={{ bruksnr, festenr, gaardsnr, kommunenr, seksjonsnr }}
          utgatt={eiendomInfo?.isUtgaatt ?? false}
          tinglyst={eiendomInfo?.isTinglyst ?? false}
        />
        <Kartutsnitt />
      </Flex>
      <MatrikkelInfo />
    </>
  );
};
