import { KvibProvider } from "@kvib/react";
import { App } from "App";
import React from "react";
import ReactDOM from "react-dom/client";

import { FeatureFlagsProvider } from "./shared/context/FeatureFlagContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <KvibProvider>
      <FeatureFlagsProvider>
        <App />
      </FeatureFlagsProvider>
    </KvibProvider>
  </React.StrictMode>,
);
