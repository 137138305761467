export function formatNumber(num: number): string {
  const formatter = new Intl.NumberFormat("no-NO", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(num);
}

export function capitalizeFirstChar(input?: string): string {
  if (input === undefined || input.length === 0) {
    return "";
  }

  const lowercaseString = input.toLowerCase();
  const firstChar = lowercaseString.charAt(0).toUpperCase();
  const restOfString = lowercaseString.slice(1);

  return firstChar + restOfString;
}
