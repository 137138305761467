import React, { createContext, ReactNode, useContext } from "react";

import useFeatureFlags from "../hooks/useFeatureFlags";
import { defaultFeatureFlags, FeatureFlags } from "../utils/fetchFeatureFlags";

interface FeatureFlagsProviderProps {
  children: ReactNode;
}

const FeatureFlagsContext = createContext<FeatureFlags | null>(null);

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => {
  const { featureFlags, loading } = useFeatureFlags();

  return (
    <FeatureFlagsContext.Provider
      value={!loading ? featureFlags : defaultFeatureFlags}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlagsContext = (): FeatureFlags => {
  const context = useContext(FeatureFlagsContext);
  if (context === null) {
    throw new Error(
      "useFeatureFlagsContext must be used within a FeatureFlagsProvider",
    );
  }

  return context;
};
