import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";

import { PartialIdent, ServerState } from "./apiDataTypes";
import { getBaseUrl } from "./config";
import { useKartutsnittInfo } from "./useKartutsnittInfo";
import { useSWRFetchers } from "./useSWRFetchers";

export const useKartutsnitt = (ident: PartialIdent): ServerState<string> => {
  const { imageFetcher } = useSWRFetchers();
  const { data: kartutsnittInfo } = useKartutsnittInfo(ident);

  const url = () =>
    kartutsnittInfo
      ? `${getBaseUrl()}/api/kartutsnitt/id/${kartutsnittInfo.teigid}`
      : null;

  const { data, error } = useSWRImmutable<string, AxiosError>(
    url,
    imageFetcher,
  );

  if (url == undefined) {
    return {
      data: undefined,
      error: new Error("Ugyldig matrikkelenhetident"),
      isLoading: false,
    };
  } else if (error != undefined) {
    return { data: undefined, error, isLoading: false };
  } else if (data != undefined) {
    return { data, error: undefined, isLoading: false };
  } else {
    return { data: undefined, error, isLoading: true };
  }
};
