import { useEffect, useState } from "react";

import { FeatureFlags, fetchFeatureFlags } from "../utils/fetchFeatureFlags";

const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFeatureFlags = async () => {
      const flags = await fetchFeatureFlags();
      setFeatureFlags(flags);
      setLoading(false);
    };

    void loadFeatureFlags();
  }, []);

  return { featureFlags, loading };
};

export default useFeatureFlags;
