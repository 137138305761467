export type FeatureFlags = {
  showMinEiendomLink: boolean;
};

export const defaultFeatureFlags: FeatureFlags = {
  showMinEiendomLink: false,
};

export const fetchFeatureFlags = async (): Promise<FeatureFlags> => {
  const response = await fetch("/configs/feature-flags.json");

  return (await response.json()) as FeatureFlags;
};
